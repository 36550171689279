/*
 * 업무구분: 영업
 * 화 면 명: MSPFS313M
 * 화면설명: 거래취소2035
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40 w100p">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호</label>
                  <mo-decimal-field  
                    numeric
                    v-model="lv_ed_accn_no.value" 
                    ref="ed_accn_no"
                    :class="lv_ed_accn_no.error ? 'error' : ''"                    
                    @keyup="fn_SearchValid($event)"
                    @paste="fn_SearchValid($event, 'P')"
                    mask="#######-###"
                    class="w130 input-account" 
                    />
                  <mo-dropdown 
                    class="w340" 
                    ref="ed_acct_gb"
                    :items="lv_acct_list" 
                    v-model="lv_ed_acct_gb.value" 
                    placeholder=" "
                    :disabled="lv_ed_acct_gb.disabled"
                    @input="fn_changeAccNo"
                    />
                  <mo-text-field 
                    v-model="lv_ed_cust_nm.value" 
                    class="w130" 
                    disabled 
                    />
                </div>
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 비밀번호 </label>
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    ref="password"
                    class="w80"
                    v-model="lv_edt_secure_passwd"
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt"         
                  />
                  <mo-text-field 
                    v-else
                    v-model="lv_edt_secure_passwd" 
                    ref="password" 
                    type="password" 
                    class="w80" 
                    maxlength="4" 
                  />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row">
                  <label class="emphasis"> 기준일자</label>
                  <mo-date-picker v-model="lv_cal_std_dt" :bottom="false" class="w130" />
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 유선구분 </label>
                  <mo-radio-wrapper :items="rdo_ars_gb" v-model="lv_ars_gb" class="row" disabled/>
                </div>
              </div>
              <div class="right w100p">
                <div class="wrap-button row">
                  <mo-button class="btn-clear"  @click="fn_init"> 초기화 </mo-button>
                  <mo-button primary class="btn-inquiry" ref="btn_search" @click="fn_searchList"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->
      
      <ur-box-container direction="column" class="mt15" alignV="start">
        <div class="wrap-table h-scroll">
          <table class="table row-type">
            <thead>
              <th class="w80">선택</th> <!-- class명 "w80" 추가 2023-04-11 jy -->
              <th>거래번호</th>
              <th>거래구분</th>
              <th>거래금액</th>
              <th>거래좌수</th>
              <th>잔고좌수</th>
              <th>처리점</th>
              <th>처리자</th> <!-- 수정 2023-04-25 -->
            </thead>
            <tbody>
              <tr v-for="(row, i) in ds_detail" :key="i">
                <td> <mo-button @click="grd_rp203501_o_00vo_oncellclick(i)"> 취소 </mo-button> </td>
                <td> {{row.bfcer_dl_no}} </td>
                <td> {{row.bfcer_otln_nm}} </td><!--  콤보박스 들어갈 자리 lv_commCode.code307 -->
                <td class="aR"> {{$commonUtil.numberWithCommas(row.bfcer_dl_amt)}} </td> <!-- class명 "aR" 추가 2023-04-11 jy -->
                <td class="aR"> {{row.bfcer_dl_shrs}} </td> <!-- class명 "aR" 추가 2023-04-11 jy -->
                <td class="aR"> {{$commonUtil.numberWithCommas(row.bfcer_blc)}} </td> <!-- class명 "aR" 추가 2023-04-11 jy -->
                <td> {{row.bfcer_fof_abbr_nm}} </td>
                <td> {{row.bfcer_eply_nm}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

    <!-- popup318 -->
    <msp-fs-318p
      ref="popup318"
      @fs-alert-popup="fn_AlertPopup"
      @fn_Popup_CallBack318="fn_Popup_CallBack318"
      :popupObj="pPopup318Obj"
    ></msp-fs-318p>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSBizUtil from '~/src/ui/fs/comm/FSBizUtil'  
  import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'  
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)

  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import FSDocForm from '@/ui/fs/comm_elst/FSDocFormUtil'   // 전자서식 (공통)

  import MSPFS318P from '~/src/ui/fs/MSPFS318P'  // 거래취소P

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS313M",
    screenId: "MSPFS313M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'fs-cust-info': FSCustInfo,
      'msp-fs-318p': MSPFS318P, //고객정보 
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode()
    },
    mounted() {
      this.fn_init()

      this.$bizUtil.insSrnLog('MSPFS313M')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear: false,            // 보안키패드 초기화
        // 공통 객체
        pHeaderObj: {
          title: '거래취소',
          step: '',
        },

        pAlertPopupObj: {},
        lv_commCode: {},
        pCustInfoObj: {},

        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,

        lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,

        // 팝업 객체
        pPopup318Obj: {},

        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

        // 검색조건
        lv_ed_accn_no: {},        // 계좌번호
        lv_ed_acct_gb: {},        // 계좌종류
        lv_ed_cust_nm: {},        // 고객명

        // 거래내역정보
        lv_cust_info: {},         // 고객정보
        lv_acct_list: [],         // 계좌조회목록

        // Accordion Toggle
        isActive: false,
        
        lv_edt_secure_passwd : '', // 비밀번호 
        lv_cal_std_dt : '', //기준일자
        lv_ars_gb : 'N', //유선구분값
        ds_detail : [], 

        // asis 전역변수 
        fv_trCode : "RP2035",
        fv_media : "I",
        gArs_passv_yn : "" ,

        lv_masked_val: '',    // 마스크 변수 체크

        ds_report : [], //리포트 변수

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      rdo_ars_gb() { // 유선구분
        let rtn = [];
        rtn.push({value: 'N', text: '일반'});
        rtn.push({value: 'Y', text: '유선'});
        return rtn;
      }, 
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val
        if(FSCommUtil.gfn_length(this.lv_masked_val) >= 4) {
          // this.fn_SearchList()
        }
      },

      /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
       ******************************************************************************/
      fn_init(){
        console.log('313 초기화 ')
        console.log('lv_basInfo 사용자 정보 ', this.lv_basInfo)
        // 검색조건
        this.lv_ed_accn_no            = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no')      // 계좌번호
        this.lv_ed_acct_gb            = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb')       // 계좌종류
        this.lv_ed_cust_nm            = FSCommUtil.gfn_dataSet(1)                                   // 고객명
        // 고객정보 초기화
        this.$refs.fsCustInfo.fn_Init()

        
        this.lv_cal_std_dt = FSCommUtil.gfn_dateReplace(1, this.lv_basInfo.busyDate)

        console.log('기준일자', this.lv_basInfo.busyDate)
        console.log('기준일자', this.lv_cal_std_dt)
        this.ds_detail = []
          
        /*------------------------------------------*/
        /* ◈ 유무선구분 입력 공통DIV 조회옵션 설정    */
        /*------------------------------------------*/
        this.gArs_passv_yn = "";
        //todo.. 확인중
        // this.div_search.div_ars_gb.fn_init(this);										/* 초기화 */
        // this.div_search.div_ars_gb.setPasswdCtrl(this.div_search.edt_secure_passwd);		/* 비밀번호 입력창을 설정해줌 : 선택시 활성화 비활성화 */
        // this.div_search.div_ars_gb.setObjTgtCtrl(this.div_search.div_accn_no);			/* 계좌컨트롤 */
        // this.div_search.div_ars_gb.setOnValueChanged(this.cbArsValueChanged);			/* 라디오 버튼 값 변경시 호출 함수 설정 : 필요시 */
        // this.div_search.div_ars_gb.setOnComplete(this.cbArsRltComplete);				/* ARS 비밀번호 체크 종료 후 호출될 함수 설정 : 필요시 */
        //보안키패드 관련
        this.lv_isClear = !this.lv_isClear ? true : false
        this.lv_masked_val = ''
        this.lv_edt_secure_passwd = ""


        
      },

      /******************************************************************************
       * Function명 : fn_Acct_no_call
       * 설명       : 조회조건 계좌번호 검색 Callback 함수
       ******************************************************************************/
      fn_Acct_no_call(){
        //그리드 초기화
        this.gArs_passv_yn = ""
        this.lv_ars_gb = "N"
        this.lv_edt_secure_passwd = ""
        this.lv_masked_val = ""
        this.ds_detail = []
      },
      /******************************************************************************
       * Function명 : fn_validateSearch
       * 설명       : 조회 입력값 확인
       ******************************************************************************/
      fn_validateSearch(){
        //계좌번호
        if(this.lv_ed_accn_no.value == '' || FSCommUtil.gfn_length(this.lv_ed_accn_no.value) < 10){
          FSCommUtil.gfn_validate(this, "계좌번호 입력오류입니다.")
          this.$refs['ed_accn_no'].focus()
          this.lv_ed_accn_no.error = true;
          return false;
        }

        var sAcctNo;
        if (FSCommUtil.gfn_isNull(this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue)){
          sAcctNo = "";
        } 
        sAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue;

        if (String(sAcctNo).length != 13) {
          FSCommUtil.gfn_validate(this, "계좌번호 입력오류입니다.")
          this.$refs['ed_accn_no'].focus()
          return false;
        }
        
        //비밀번호
        var sPassWd = this.isMobile ? this.lv_masked_val : this.lv_edt_secure_passwd
        if (FSCommUtil.gfn_isNull(sPassWd)) sPassWd = "";
        if( this.lv_ars_gb != "Y" ) {
          if (FSCommUtil.gfn_length(sPassWd) < 4) {
            FSCommUtil.gfn_validate(this, "비밀번호 입력오류입니다.")
            this.$refs['password'].focus()

            this.lv_isClear = !this.lv_isClear ? true : false
            this.lv_masked_val = ''
            this.this.lv_edt_secure_passwd = ''

            return false;
          }
        }
        return true;
      },
      /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : 조회
       ******************************************************************************/
      fn_searchList(){
        if (!this.fn_validateSearch()) return;

        this.ds_detail = [];

        // todo.. 확인중 
        // trace(this.ds_rp203500_i_01vo.saveXML());
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570222_S'
        this.eaiCommObj.params = {
          bfcer_accn_no      : this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, // 수익증권계좌번호, Length : 13
          callcenter_link_yn : "N", // 접근, Length : 1
          dl_ymd             : this.lv_cal_std_dt.replace(/-/g, ''), // 거래일자, Length : 8
          secure_passv       : this.lv_edt_secure_passwd, // 비밀번호 
          tr_code            : this.fv_trCode, // 거래코드 
          ars_pswn_yn        : '',//this.lv_ars_gb, //비밀번호 체크
          bfcer_trt_fof_cd   : '', // 처리지점 
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_resultSearchList)
        // var encALGO = "SEED";
        // sArgument += " ENCSYMM = " + nexacro.wrapQuote(nexacro.secureSession.getKeyIV());
        // sArgument += " ENCALGO = " + nexacro.wrapQuote(encALGO);
      },

      /******************************************************************************
       * Function명 : fn_resultSearchList
       * 설명       : 조회 callback
       ******************************************************************************/
      fn_resultSearchList(response){
        console.log('조회callback', response)
        let t_list = [];
        let t_data = response.data
        if( t_data.error_msg == "809900" ){

          this.ds_detail = response.data.rp203500_O_01VO;
          let t_codeList = [];
          t_codeList = this.lv_commCode.code317;

          let lv_Vm = this;
          console.log('t_codeList.length', lv_Vm.lv_commCode.code307.length);
          console.log('lvvm_ds_detail', lv_Vm.ds_detail);
          console.log('lvvm_ds_detail', lv_Vm.ds_detail.length);
          
          if(lv_Vm.ds_detail){
            lv_Vm.ds_detail.forEach((item, idx) => {
              var findIndx = lv_Vm.lv_commCode.code307.findIndex(i => i.value == item.bfcer_otln_cd)
              
              t_list.push({
                bfcer_accn_no     : item.bfcer_accn_no,
                bfcer_blc         : item.bfcer_blc,
                bfcer_dl_amt      : item.bfcer_dl_amt,
                bfcer_dl_no       : item.bfcer_dl_no,
                bfcer_dl_shrs     : item.bfcer_dl_shrs,
                bfcer_eply_nm     : item.bfcer_eply_nm,
                bfcer_fof_abbr_nm : item.bfcer_fof_abbr_nm,
                bfcer_otln_cd     : item.bfcer_otln_cd,
                bfcer_otln_nm     : lv_Vm.lv_commCode.code307[findIndx].text,
                bfcer_pnlt_ihtax  : item.bfcer_pnlt_ihtax,
                bfcer_pnlt_imtax  : item.bfcer_pnlt_imtax,
                bfcer_pnlt_stxff  : item.bfcer_pnlt_stxff,
                bfcer_rpus_coms   : item.bfcer_rpus_coms,
                bfcer_trnst_cd    : item.bfcer_trnst_cd,
                cmnt_cntnt        : item.cmnt_cntnt,
                cnvr_coms         : item.cnvr_coms,
                dl_ymd            : item.dl_ymd,
                entry_coms        : item.entry_coms,
                frm_issnc_ymd     : item.frm_issnc_ymd,
                issnc_hstr_sno    : item.issnc_hstr_sno,
                pageRowCnt        : item.pageRowCnt,
                rplc_bfcer_accn_no: item.rplc_bfcer_accn_no,
                rplc_dl_no        : item.rplc_dl_no,
                sale_coms         : item.sale_coms,
                stndKeyId         : item.stndKeyId
              })
            })
          }
          console.log('t_list result ', t_list);
          this.ds_detail = t_list;
          console.log('this.ds_detail', this.ds_detail);
          console.log('this.ds_detail2', t_list);
        
        } 
        else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
        }
        
        if( t_data.error_msg == "010050"){
          this.lv_isClear = !this.lv_isClear ? true : false
          this.lv_masked_val = ''
          this.lv_edt_secure_passwd = ""
        }
      },

      /******************************************************************************
       * Function명 : grd_rp203501_o_00vo_oncellclick
       * 설명       : 그리드 클릭시 
       ******************************************************************************/
      grd_rp203501_o_00vo_oncellclick(idx){
        console.log('삭제버튼 클릭 시', idx);
        let objDs = [];
        objDs.push(this.ds_detail[idx]);
        this.pPopup318Obj = {};
        this.pPopup318Obj.pTrCode  = this.fv_trCode;
        this.pPopup318Obj.pMedia   = this.fv_media;
        this.pPopup318Obj.pDataSet = objDs;
        this.pPopup318Obj.pStdDt   = this.lv_cal_std_dt;
        this.pPopup318Obj.arsGb    = this.lv_ars_gb;
        console.log('p318 obj ', this.pPopup318Obj)
        this.$refs.popup318.fn_Open()	
        // var sRtn = this.gfn_openPopup("RP203501P","rp::RP203501P.xfdl", oArg, sOption, sPopupCallBack);		
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack318
       * 설명       : 팝업 콜백  
       ******************************************************************************/
      fn_Popup_CallBack318(type, pData){
        console.log('318 callback ,,', pData)
          if(pData != "N"){
            // let t_popupObj = {content: "정상 처리되었습니다."}
            // this.fn_AlertPopup(type, {})
            //정상처리후 취소버튼 삭제하기
            // 모화면 재조회
            if(this.ds_detail.length > 1){
              this.fn_searchList()
            }
            this.gArs_passv_yn        = ""
            this.lv_ars_gb            = "N"
            this.lv_edt_secure_passwd = ""
            this.lv_masked_val        = ""
            this.lv_isClear           = !this.lv_isClear ? true : false // 보안키패드 초기화

            this.ds_report = [...this.ds_report]
            this.ds_report = pData
            var reportPath = pData.frm_file_path_nm
            var reportFile = pData.frm_file_nm
            let t_msg = FSMessageUtil.commMessage([])[pData.error_msg]  

            if( FSCommUtil.gfn_isNull(reportPath)) {
              //취소시는 보고서 출력이 없는 업무도 있으므로 그냥retuen한다.
              //this.gfn_getMessage("Alert","Report 양식 경로가 존재하지 않습니다.");
              //return ;
            }else{
              if ( FSCommUtil.gfn_isNull(reportFile)) {
                t_popupObj = {content: "Report 양식 파일명이 존재하지 않습니다."}
                this.fn_AlertPopup(0, t_popupObj)
                return;
              }
                
              console.log("report start");
              let t_popupObj = {
                confirm: true,
                content: [
                  t_msg
                ],
                confirmFunc: this.fn_ReportPrint,
                confirmData: {
                  bfcer_cust_no: pData.bfcer_cust_no
                }
              }
              this.fn_AlertPopup(9, t_popupObj)
            }

          }
      },      

      fn_rtnCotlnCdNm(cd){
        var rtnVal = ''
        let pData = this.lv_commCode.code317
        pData.forEach(item => {
          if(item.value == cd){
            rtnVal = item.text
          }
        })
        return rtnVal;
      },

       /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')

        let formList = []
        let FS000014 = {formId: 'FS000014'}
        let searchData = this.ds_report      
        // let searchData = {
        //   bfcer_cust_no    : t_data.bfcer_cust_no,
        //   frm_issnc_ymd    : t_data.frm_issnc_ymd,
        //   issnc_hstr_sno   : t_data.issnc_hstr_sno,
        //   bfcer_accn_no    : t_data.bfcer_cust_no,
        //   bfcer_busn_trt_id: 'RP2035',
        //   rprt_frm_no      : t_data.rprt_frm_no,
        // }
        
        FS000014.params = {
          reportParam: JSON.stringify(searchData),        
          reportUrl: 'reportCO070500_Info',
        }

        formList.push(FS000014)

        FSInfoUtil.commReportInfo(this, formList)
      },
      
      /******************************************************************************
       * Function명 : fn_changeAccNo
       * 설명       : 계좌 변경 시 비밀번호 초기화 
       ******************************************************************************/
      fn_changeAccNo(){
        console.log('fn_changeAccNo', this.lv_masked_val);
        this.lv_isClear = !this.lv_isClear ? true : false
        this.lv_masked_val = ''
        this.lv_edt_secure_passwd = ''

      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event, flag) {
        this.lv_edt_secure_passwd = ''
        this.lv_masked_val = ''
        this.lv_ed_accn_no.error = false
        console.log('fn_SearchValid.....', event)
        // if(event.key == "Backspace"){
        //   this.fn_init();
        // }

				let t_accn_no = this.lv_ed_accn_no.value
        // if( flag == 'P' ) {
        //   t_accn_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,10)
        // } else {
        //   if( !FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value) ) {
        //     t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10)
        //     console.log('계좌번호', t_accn_no);
        //     console.log('replace before', this.lv_ed_accn_no.value);
        //   }
        // } 

        if( FSCommUtil.gfn_length(t_accn_no) >= 10 ) {
          this.lv_ed_accn_no.error = false

          if( !FSCommUtil.gfn_isNum(t_accn_no) ) {
            FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
            this.lv_ed_accn_no.error = true
            this.$refs['ed_accn_no'].focus()
            return 

          } else {
            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile ) {
              // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

              // 계좌목록조회
              this.fn_AccnNoList(t_accn_no)
            }
          }
        } else if( t_accn_no.length > 7 || event.key == 'Delete' || event.key == 'Backspace' ) {
          // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

          // 계좌목록/고객명 초기화
          this.lv_acct_list = []
          this.lv_ed_acct_gb.disabled = true
          this.lv_ed_cust_nm.value = ''

          // 고객정보 펼치기 정보 초기화
          this.$refs.fsCustInfo.fn_Init()
        }

      },

      /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo() {
        console.log('fn_CustInfo.....')

        this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
        this.$refs.fsCustInfo.fn_CustInfoSearch()
      },

      fn_SetEdCustName(val) {
        // 고객명
        this.lv_ed_cust_nm.value = val
      },


      /******************************************************************************
       * Function명 : fn_AccnNoList, fn_AccnNoListResult
       * 설명       : 계좌목록조회
       ******************************************************************************/
      fn_AccnNoList(pAccnNo) {
        console.log('fn_AccnNoList.....')

        let t_data = {bfcer_cstgr_no : pAccnNo}
        FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
      },

      fn_AccnNoListResult(pResultData) {
        console.log('fn_AccnNoListResult.....')

        let t_data = pResultData.data.sy000000_O_00VO
        console.log(t_data)

        // 계좌목록조회 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true

        if( t_data.length > 0 ) {
          t_data.forEach((item, idx) => {
            this.lv_acct_list.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
          })
          
          // 계좌목록조회 결과 첫번째 선택 및 활성화
          this.lv_ed_acct_gb.value = 0
          this.lv_ed_acct_gb.disabled = false

          // 거래내역조회 결과 초기화
          this.lv_trd_list = []

          // 고객정보조회
          this.fn_CustInfo()
          
        } else {
          let t_popupObj = {content: "검색할 계좌가 없습니다."}
          this.fn_AlertPopup(0, t_popupObj)
        }
      },

      
      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'307', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },
      fn_SetCommCodeResult(pResult) {
        console.log('공통코드', pResult);
         this.lv_commCode = pResult
      },

      /******************************************************************************
        * Function명 : fn_AlertPopup
        * 설명       : alert popup 호출(공통)
        ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive;
      },
    },

    

      

    
  }
</script>
<style scoped>
</style>