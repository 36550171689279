/*
 * 업무구분: 수익증권>영업>거래취소>거래취소_P
 * 화 면 명: MSPFS318P
 * 화면설명: 거래취소 POPUP
 * 작 성 일: 2023.03.22
 * 작 성 자: 이지수
 */
<template>
    <!-- popup -->
    <mo-modal class="fts-modal large" ref="modal" title="거래취소">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table">
            <table class="table col-type col1634 th-white th-border-left"> <!-- class명 교체 "col2030 > col1634" 2023-04-11 jy -->
              <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
              <tbody>
                <tr>
                  <th>
                    <span> 취소사유 </span>
                  </th>
                  <td colspan="3">
                    <mo-radio-wrapper :items="lv_commCode.code293" v-model="lv_canc_resn_gb" ref="cancResnGb" class="row"/>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 거래일 </span>
                  </th>
                  <td>
                     <mo-date-picker v-model="lv_cal_trd_dt" :bottom="false" class="w130" disabled />
                  </td>
                  <th>
                    <span> 거래번호 </span>
                  </th>
                  <td>
                    <mo-text-field class="input-long" v-model="lv_edt_trd_no" disabled />
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 거래구분 </span>
                  </th>
                  <td>
                    <mo-dropdown :items="lv_commCode.code307" v-model="lv_summ_cd" disabled />
                  </td>
                  <th>
                    <span> 거래금액 </span>
                  </th>
                  <td>
                    <mo-text-field class="input-long" mask="number" v-model="lv_mae_trd_amt" disabled />
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 거래좌수 </span>
                  </th>
                  <td>
                    <mo-text-field class="input-long" mask="number" v-model="lv_mae_trd_qty" disabled />
                  </td>
                  <th>
                    <span> 잔고좌수 </span>
                  </th>
                  <td>
                    <mo-text-field class="input-long" mask="number" v-model="lv_mae_remn_qty" disabled />
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 처리점 </span>
                  </th>
                  <td>
                    <mo-text-field class="input-long"  v-model="lv_edt_proc_br" disabled />
                  </td>
                  <th>
                    <span> 조작자 </span>
                  </th>
                  <td>
                    <mo-text-field class="input-long" v-model="lv_edt_work_empno" disabled />
                  </td>
                </tr>
                <tr>
                  <th>
                    <span> 유선구분 </span>
                  </th>
                  <td colspan="3">
                    <mo-radio-wrapper :items="rdo_ars_gb" v-model="lv_ars_gb" ref="arsGb" class="row" disabled />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close('N')">닫기</mo-button>
          <mo-button primary size="large" @click="fn_btn_confirm_onclick">확인</mo-button>
        </div>
      </template>
    </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS318P",
  screenId: "MSPFS318P",
  components: {},
  props: {
    popupObj: {type:Object, default:null},
  },  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS318P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {

      lv_commCode: {},
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
        
      lv_ars_gb : 'N', // 유선구분 
      lv_edt_work_empno : '', // 조작자
      lv_edt_proc_br : '', // 처리점 
      lv_mae_remn_qty : '', // 잔고좌수
      lv_mae_trd_qty : '', // 거래좌수
      lv_mae_trd_amt : '', // 거래금액
      lv_summ_cd : '', // 거래구분
      lv_edt_trd_no : '', // 거래번호 
      lv_cal_trd_dt : '', // 거래일 
      lv_canc_resn_gb : '11', // 취소사유 

      ds_report : [], 
      ds_rp203500_o_01vo : [],

      tv_media : '',
      tv_trCode : '',
      fv_stdDt : '',
      fv_arsGb : '',
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    modal() {
      return this.$refs.modal
    },
    rdo_ars_gb() { // 유선구분
      let rtn = [];
      rtn.push({value: 'N', text: '일반'});
      rtn.push({value: 'Y', text: '유선'});
      return rtn;
    }, 

  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId':'307', 'dayChkYn':'N'},
        {'cId':'293', 'dayChkYn':'N'},
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      console.log('공통코드 세팅', pResult);
      this.lv_commCode = pResult

      console.log('popup open', this.popupObj);
      this.tv_trCode = this.popupObj.pTrCode;
      this.tv_media  = this.popupObj.pMedia;
      this.fv_stdDt  = this.popupObj.pStdDt;
      this.fv_arsGb  = this.popupObj.arsGb;
      
      console.log( "parent : " + this.tv_trCode + " " + this.tv_media + " " + this.fv_stdDt + " " + this.fv_arsGb);
      
      this.ds_rp203500_o_01vo = [];
      this.ds_rp203500_o_01vo = this.popupObj.pDataSet;
       
      if(FSCommUtil.gfn_length(this.ds_rp203500_o_01vo) > 0){
        //취소사유 
        this.lv_canc_resn_gb = '11';
        //거래일 
        this.lv_cal_trd_dt =FSCommUtil.gfn_dateReplace(1, this.ds_rp203500_o_01vo[0].dl_ymd);
        //거래번호 
        this.lv_edt_trd_no = this.ds_rp203500_o_01vo[0].bfcer_dl_no;
        //거래구분
        this.lv_summ_cd = this.ds_rp203500_o_01vo[0].bfcer_otln_cd;
        //거래금액
        this.lv_mae_trd_amt = this.ds_rp203500_o_01vo[0].bfcer_dl_amt;
        //거래좌수
        this.lv_mae_trd_qty = this.ds_rp203500_o_01vo[0].bfcer_dl_shrs;
        //잔고좌수
        this.lv_mae_remn_qty = this.$bizUtil.numberWithCommas(Number(this.ds_rp203500_o_01vo[0].bfcer_blc));
        //처리점
        this.lv_edt_proc_br = this.ds_rp203500_o_01vo[0].bfcer_fof_abbr_nm;
        //조작자 
        this.lv_edt_work_empno = this.ds_rp203500_o_01vo[0].bfcer_eply_nm;
        //유선구분 초기화
        this.lv_ars_gb = "N"
        this.lv_ars_gb = this.fv_arsGb;
        
        if (this.ds_rp203500_o_01vo[0].cmnt_cntnt == "유보세환매") {
          let t_popupObj = {content: "유보세를 납부하였습니다. 환급하여 주시길 바랍니다."}
          this.fn_AlertPopup(0, t_popupObj)
        }
      }
      this.modal.open()
    },
    // modal
    fn_Open() {
      this.fn_SetCommCode();
    },
    fn_Close(pData) {
      if(pData != 'N'){

        let lv_vm = this
        let t_popupObj = {
          confirm: true,
          content: '처리가 완료되었습니다.',
          cancel: false,
          confirmFunc: function(){
            lv_vm.$emit('fn_Popup_CallBack318', 0, pData)
            lv_vm.modal.close()
          },
          // confirmData: {
          //   bfcer_cust_no: lv_vm.ds_rp203500_o_01vo[0].bfcer_accn_no.substr(0,7)
          // }
        }
        this.fn_AlertPopup(0, t_popupObj)
        
      }else{
        this.modal.close()
      }
      

    },

    /******************************************************************************
     * Function명 : fn_btn_confirm_onclick
     * 설명       : 확인버튼 클릭 시 
     ******************************************************************************/
    fn_btn_confirm_onclick(){
      if (!this.fn_validateConfirm()) return;

      // trace(this.ds_rp203500_i_02vo.saveXML());



      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570223_S'
      this.eaiCommObj.params = {
        acct_seq_no        : this.ds_rp203500_o_01vo[0].bfcer_accn_no, //계좌일련번호
        callcenter_link_yn : this.lv_ars_gb , //접근
        cust_no            : this.ds_rp203500_o_01vo[0].bfcer_accn_no.substr(0,7), //고객번호
        pbk_seq_no         : "", //통장알련번호
        bfcer_accn_no      : this.ds_rp203500_o_01vo[0].bfcer_accn_no,
        canc_resn_gb       : this.lv_canc_resn_gb,
        std_dt             : this.popupObj.pStdDt.replaceAll('-', ''), 
        summ_cd            : this.ds_rp203500_o_01vo[0].bfcer_otln_cd,
        trd_dt             : this.ds_rp203500_o_01vo[0].dl_ymd.replaceAll('-', ''),
        trd_no             : this.ds_rp203500_o_01vo[0].bfcer_dl_no,
        tr_code            : this.tv_trCode,
        tel_use_yn         : 'N'
      }
      console.log('거래취소팝업 param', this.eaiCommObj.params);
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_resultConfirm)
      
      // //서버에 호출 할 정보를 셋팅해준다.
      // var sSvcID        = "processRP203500_cancel";
      // var sURL          = "svc::processRP203500_cancel.do";
      // var sInDatasets   = "rp203500_i_02vo=ds_rp203500_i_02vo";
      // var sOutDatasets  = "ds_rp203500_o_00vo=rp203500_o_00vo";
      // var sArgument     = "";
      // var sCallbackFunc = "fn_resultConfirm";

      // //암호화key 서버 올림
      // var encALGO = "SEED";
      // sArgument += " ENCSYMM = " + nexacro.wrapQuote(nexacro.secureSession.getKeyIV());
      // sArgument += " ENCALGO = " + nexacro.wrapQuote(encALGO);

      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
    },

    /******************************************************************************
     * Function명 : fn_resultConfirm
     * 설명       : callback
     ******************************************************************************/
    fn_resultConfirm(response){
      console.log('fn_resultConfirm', response);
      let t_popupObj = {}
      if( response.data.error_msg == "809900" || response.data.error_msg == "809902" || response.data.error_msg == "809904" ||        
          response.data.error_msg == "809905"){        
        // let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
        // this.fn_AlertPopup(0, t_popupObj)        
        let t_data = response.data
              

        this.ds_report = t_data
        
         if ( this.lv_ars_gb == "N" ){
          //todo.. xml확인중 
          // console.log( this.ds_rp203500_o_00vo.saveXML() );
          this.fn_Close(t_data);
          
        }

        
       }else if(response.data.error_msg == " "){
        t_popupObj = {content: '처리 중 오류가 발생하였습니다.'}
        this.fn_AlertPopup(0, t_popupObj)

       }else{
        t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
        this.fn_AlertPopup(0, t_popupObj)
       }

       
        
    },

    

    
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup(type, t_obj) {
        let t_popupObj = t_obj;
        this.$emit('fs-alert-popup', type, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_validateConfirm
     * 설명       : 확인버튼 클릭 시 Validation 처리
     ******************************************************************************/
    fn_validateConfirm(){
      if (FSCommUtil.gfn_isNull(this.lv_canc_resn_gb))	{
        let t_popupObj = {content: "취소사유 입력오류입니다."}
        this.fn_AlertPopup(0, t_popupObj)
        this.$refs['cancResnGb'].focus();
        return false;
      }
      if (FSCommUtil.gfn_isNull(this.lv_ars_gb))	{
        let t_popupObj = {content: "유선구분 입력오류입니다."}
        this.fn_AlertPopup(0, t_popupObj)
        this.$refs['arsGb'].focus();
        return false;
      } 
      return true;
    }

  }
};
</script>
<style scoped>
</style>